import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Footer from '../components/Footer'
import Ccby from '../components/Ccby'
import Content, { HTMLContent } from '../components/Content'
import Img from 'gatsby-image'
import Url from '../components/Url'

export const AboutPageTemplate = ({
  title,
  content,
  contentComponent,
  featuredimage,
  image_alt,
  attribution_alt,
  attribution_text,
  attribution_link,
  location_alt,
  location_text,
  location_link,
}) => {
  const PageContent = contentComponent || Content

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h2>
              <Img
                fluid={featuredimage.childImageSharp.fluid}
                alt={image_alt}
              />
              <div className="column is-12 has-text-right pr-6 is-size-7 has-text-grey">
                Photo by{' '}
                <Url
                  url={attribution_link}
                  alt={attribution_alt}
                  text={attribution_text}
                />{' '}
                on{' '}
                <Url
                  url={location_link}
                  alt={location_alt}
                  text={location_text}
                />
              </div>
              <PageContent className="content" content={content} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  featuredimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  image_alt: PropTypes.string,
  attribution_alt: PropTypes.string,
  attribution_text: PropTypes.string,
  attribution_link: PropTypes.string,
  location_alt: PropTypes.string,
  location_text: PropTypes.string,
  location_link: PropTypes.string,
}

const AboutPage = ({ data }) => {
  const { mdx: post } = data

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        image={post.frontmatter.featuredimage}
        paymentpointer={post.frontmatter.paymentpointer}
      />
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.body}
        featuredimage={post.frontmatter.featuredimage}
        image_alt={post.frontmatter.image_alt}
        attribution_alt={post.frontmatter.attribution_alt}
        attribution_text={post.frontmatter.attribution_text}
        attribution_link={post.frontmatter.attribution_link}
        location_alt={post.frontmatter.location_alt}
        location_text={post.frontmatter.location_text}
        location_link={post.frontmatter.location_link}
      />
      <Footer>
        <Ccby />
      </Footer>
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        description
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image_alt
        attribution_alt
        attribution_text
        attribution_link
        location_alt
        location_text
        location_link
        paymentpointer
      }
    }
  }
`
